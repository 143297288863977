<template>
    <div>
        <div class="input-container">
            <input ref="input" @change="preview" :name="name" style="display: inline-block" type="file" accept="image/*" capture multiple/>
            <button @click="clear" type="button" class="btn btn-sm btn-danger">
                <i class="fa fa-fw fa-times"></i>
                Effacer la sélection
            </button>
        </div>

        <div v-if="images.length" class="thumbs" style="margin-top: 20px">
            <a v-for="image in images" :href="image" class="thumb-container" data-fancybox="uploaded">
                <img :src="image"/>
            </a>
        </div>
    </div>
</template>

<script>
export default
{
    name: 'ImageUploadPreview',
    props:
    {
        name:
        {
            type: String,
            required: false,
            default: 'images'
        }
    },
    data()
    {
        return {
            images: [],
        }
    },
    methods:
    {
        preview(event)
        {
            this.images = [];

            if (event.target.files.length)
            {
                Array.prototype.forEach.call(event.target.files, file =>
                {
                    this.images.push(URL.createObjectURL(file));
                });
            }
        },
        clear()
        {
            this.$refs.input.value = '';
            this.$refs.input.dispatchEvent(new Event('change'));
        }
    }
}
</script>

<style lang="scss" scoped>
    .input-container
    {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>